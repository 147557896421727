





















































































































































































































































































































































import { IOption } from "@interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import moment, { Moment } from "moment";
import Vue from "vue";
import { ResponseListSalesOrder, ResponseSalesOrder } from "@interface/sales-order.interface";
import { salesOrderService } from "@service/sales-order.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { masterTypeService } from "@/services-v2/master-type.service";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { SALES_ORDER_STATUS } from "@enum/sales-order.enum";
import { createNamespacedHelpers, mapGetters } from "vuex";

const { mapActions } = createNamespacedHelpers("salesOrderStore");

interface ITableRow extends ResponseSalesOrder {
  key: any;
  loadingCancel: boolean;
}

export default Vue.extend({
  name: "SalesOrderList",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectBranch: () => import(/*webpackPrefetch: true*/"@/components/shared/select-branch/CSelectBranch.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      SALES_ORDER_STATUS,
      formModel: {
        customerName: undefined as string | undefined,
        customerPoNumber: "",
        branch: undefined as string | undefined,
        orderDate: null as Moment[] | null,
        etaDate: null as Moment[] | null,
        status: undefined as string | undefined,
        jobCostingNumber: "",
      },
      optStatus: [] as IOption[],
      dtTable: [] as ITableRow[],
      loading: {
        table: false,
        status: false,
      },
      queryParams: {
        page: 0,
        limit: 20,
        sorts: "orderDate:desc"
      } as RequestQueryParamsModel,
      dtListSalesOrder: {} as ResponseListSalesOrder
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivilege: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeSO(): boolean {
      return !!this.getUserPrivilege.find(x => x.key === "sales-order" && x.privilege.create && x.privilege.update);
    },
    hasPrivilegeSOWarehouse(): boolean {
      return !!this.getUserPrivilege.find(x => x.key === "sales-order-warehouse" && x.privilege.read);
    },
    formCol() {
      return {
        labelCol: {
          sm: { span: 24 },
          md: { span: 12 },
        },
        wrapperCol: {
          sm: { span: 24 },
          md: { span: 12 },
        }
      };
    }
  },
  created() {
    this.getList(this.queryParams);
    this.getListStatus();
  },
  methods: {
    moment,
    ...mapActions({
      resetStore: "RESET_STATE",
    }),
    getListSalesOrder(params: RequestQueryParamsModel): Promise<ResponseListSalesOrder> {
      return salesOrderService.getList(params);
    },
    getListMasterType(name: string): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster({ name });
    },
    cancelSalesOrder(id: string): Promise<boolean> {
      return salesOrderService.cancelSalesOrder(id);
    },
    async cancelSO(record: ITableRow): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      try {
        record.loadingCancel = true;
        await this.cancelSalesOrder(record.id);
        this.showSuccessMessage("notif_cancel_success");
        this.getList(this.queryParams);
      } catch (error) {
        this.showErrorMessage("notif_cancel_fail");
      } finally {
        record.loadingCancel = false;
      }
    },
    goToForm(record: ResponseSalesOrder): void {
      this.$router.push({
        name: "sales.transactionsales.salesorder.edit",
        params: { id: record.id },
      });
    },
    goToDetail(record: ResponseSalesOrder): void {
      this.$router.push({
        name: "sales.transactionsales.salesorder.detail",
        params: { id: record.id },
      });
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.table = true;
        this.dtListSalesOrder = await this.getListSalesOrder(params);
        this.dtTable = this.dtListSalesOrder.data.map((x, i) => {
          return {
            key: i,
            loadingCancel: false,
            ...x
          };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    async getListStatus(): Promise<void> {
      try {
        this.loading.status = true;
        const res = await this.getListMasterType("SALES_ORDER_STATUS");
        this.optStatus = res.map(x => {
          return { key: x.value, value: x.value };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.status = false;
      }
    },
    handleClear(): void {
      this.formModel = {
        customerName: undefined,
        customerPoNumber: "",
        branch: undefined,
        orderDate: null,
        etaDate: null,
        status: undefined,
        jobCostingNumber: "",
      };
    },
    handleFind(): void {
      const {
        customerName,
        customerPoNumber,
        branch,
        orderDate,
        etaDate,
        status,
        jobCostingNumber,
      } = this.formModel;
      const searchBy: string[] = [];
      if (customerName) searchBy.push(`customer.secureId~${customerName}`);
      if (customerPoNumber) searchBy.push(`customerPoNumber~*${customerPoNumber}*`);
      if (branch) searchBy.push(`branch.secureId~${branch}`);
      if (status) searchBy.push(`state~${trimSpaceToUnderscore(status)}`);
      if (jobCostingNumber) searchBy.push(`jobCosting.jobCostingNumber~*${jobCostingNumber}*`);

      if (orderDate && orderDate.length) {
        const [start, end] = orderDate;
        start.set({ hour: 0, minute: 0, second: 0});
        end.set({ hour: 23, minute: 59, second: 59});
        searchBy.push(`orderDate>=${start.utcOffset("+07").format()}_AND_orderDate<=${end.utcOffset("+07").format()}`);
      }

      if (etaDate && etaDate.length) {
        const [start, end] = etaDate;
        start.set({ hour: 0, minute: 0, second: 0});
        end.set({ hour: 23, minute: 59, second: 59});
        searchBy.push(`etaDate>=${start.utcOffset("+07").format()}_AND_etaDate<=${end.utcOffset("+07").format()}`);
      }

      this.queryParams.search = searchBy.join("_AND_");
      this.getList(this.queryParams);
    },
    handleCreateNew(): void {
      this.resetStore();
      this.$router.push({ name: "sales.transactionsales.salesorder.new" });
    },
    responsePageSizeChange (response: ResponsePagination) {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange (response: ResponsePagination) {
      this.queryParams.page = response.page - 1;
      this.getList(this.queryParams);
    },
  }
});
