var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_list_sales_order") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.formModel,
                "label-align": "left",
                "label-col": _vm.formCol.labelCol,
                "wrapper-col": _vm.formCol.wrapperCol
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_customer_name") } },
                        [
                          _c("CSelectCustomer", {
                            model: {
                              value: _vm.formModel.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerName", $$v)
                              },
                              expression: "formModel.customerName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_customer_po_number") } },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.formModel.customerPoNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerPoNumber", $$v)
                              },
                              expression: "formModel.customerPoNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_job_costing_number") } },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.formModel.jobCostingNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "jobCostingNumber", $$v)
                              },
                              expression: "formModel.jobCostingNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_branch") } },
                        [
                          _c("CSelectBranch", {
                            model: {
                              value: _vm.formModel.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branch", $$v)
                              },
                              expression: "formModel.branch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_order_date") } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              "allow-clear": "",
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            },
                            model: {
                              value: _vm.formModel.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orderDate", $$v)
                              },
                              expression: "formModel.orderDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_eta_date") } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              "allow-clear": "",
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            },
                            model: {
                              value: _vm.formModel.etaDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "etaDate", $$v)
                              },
                              expression: "formModel.etaDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "allow-clear": "",
                                "dropdown-match-select-width": false,
                                loading: _vm.loading.status
                              },
                              model: {
                                value: _vm.formModel.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "status", $$v)
                                },
                                expression: "formModel.status"
                              }
                            },
                            _vm._l(_vm.optStatus, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(item.key || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(item.key || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleClear } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "search" },
                              on: { click: _vm.handleFind }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, align: "end" } },
                    [
                      _vm.hasPrivilegeSO
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "plus" },
                              on: { click: _vm.handleCreateNew }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtTable,
                        loading: _vm.loading.table,
                        pagination: false,
                        scroll: { x: 1400, y: 500 }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "salesOrderNumber",
                          attrs: { "data-index": "salesOrderNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.salesOrderNumber || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_sales_order_no")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "jobCostingNumber",
                          attrs: { "data-index": "jobCostingNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.jobCostingNumber || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_job_costing_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "customerName",
                          attrs: { "data-index": "customerName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.customerName || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "customerPoNumber",
                          attrs: { "data-index": "customerPoNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.customerPoNumber || "-") +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_po_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "orderDate",
                          attrs: { "data-index": "orderDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(record.orderDate)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_order_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "etaDate",
                          attrs: { "data-index": "etaDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(record.etaDate)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_eta_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "branchName",
                          attrs: { "data-index": "branchName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.branchName || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_branch")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "salesName",
                          attrs: { "data-index": "salesName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.salesName || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_sales_person_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "status",
                          attrs: {
                            "data-index": "status",
                            align: "center",
                            fixed: "right",
                            width: 100
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.status || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_status")))]
                          )
                        ]
                      ),
                      _vm.hasPrivilegeSO || _vm.hasPrivilegeSOWarehouse
                        ? _c(
                            "a-table-column",
                            {
                              key: "action",
                              attrs: {
                                align: "center",
                                fixed: "right",
                                width: 100
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "a-dropdown",
                                          {
                                            attrs: {
                                              trigger: ["click"],
                                              placement: "bottomCenter"
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "more" },
                                              on: {
                                                click: function(e) {
                                                  return e.preventDefault()
                                                }
                                              }
                                            }),
                                            _c(
                                              "a-menu",
                                              {
                                                attrs: { slot: "overlay" },
                                                slot: "overlay"
                                              },
                                              [
                                                record.status ===
                                                _vm.SALES_ORDER_STATUS
                                                  .WAITING_FOR_WAREHOUSE
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm.hasPrivilegeSOWarehouse
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.goToDetail(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_update"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                record.status !==
                                                _vm.SALES_ORDER_STATUS.DRAFT
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm.hasPrivilegeSO
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.goToDetail(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("lbl_view")
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                record.status ===
                                                _vm.SALES_ORDER_STATUS.DRAFT
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm.hasPrivilegeSO
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.goToForm(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("lbl_edit")
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                record.status ===
                                                _vm.SALES_ORDER_STATUS.DRAFT
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        attrs: {
                                                          disabled: !_vm.hasPrivilegeSO
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.cancelSO(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        !record.loadingCancel
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_cancel"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          : _c("a-icon", {
                                                              attrs: {
                                                                type: "loading"
                                                              }
                                                            })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4000857333
                              )
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 16 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dtListSalesOrder.totalElements,
                      "page-size-set": _vm.queryParams.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 8, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.dtListSalesOrder.totalElements) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }